/* eslint-disable dot-notation */
import { Prop, Vue } from 'vue-property-decorator'
import app from '@/main'
import AlertModal from '@/components/modal/AlertModal.vue'
import { Subject } from 'rxjs'
import { AsyncComponent, ComponentOptions } from 'vue'
import { BrowserService } from '@/service/browser.service'

export abstract class AbstractModalVue extends Vue {
  @Prop() protected name!: string

  protected unsubscribe$ = new Subject<void>()

  protected modals: string[] = []

  showModal(
    name: string,
    component: typeof Vue | ComponentOptions<Vue> | AsyncComponent,
    componentProps?: object,
    modalProps?: object,
    modalEvents?: object) {
    this.modals.push(name)
    componentProps = componentProps ?? {}
    componentProps['name'] = name
    modalProps = modalProps ?? {}
    modalProps['name'] = name
    modalProps['adaptive'] = true
    modalProps['scrollable'] = true
    modalProps['height'] = 'auto'
    modalEvents = modalEvents ?? {}
    const closed = modalEvents['closed']
    modalEvents['closed'] = () => {
      const index = this.modals.indexOf(name)
      if (index >= 0) {
        this.modals.splice(index, 1)
        if (closed) {
          closed()
        }
      }
    }
    this.$modal.show(component, componentProps, modalProps, modalEvents)
  }

  showAlert(error: Error, closed?: () => void) {
    if (BrowserService.isCrawler()) {
      if (closed) {
        closed()
      }
      return
    }
    const name = AlertModal.NAME + '-' + Math.random()
    this.showModal(name, AlertModal, {
      error
    }, undefined, {
      closed
    })
  }

  showAlertMessage(msg: string, title?: string, closed?: () => void) {
    const name = AlertModal.NAME + '-' + Math.random()
    this.showModal(name, AlertModal, {
      title,
      message: msg
    }, undefined, {
      closed
    })
  }

  destroyed() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()

    const names = this.modals
    this.modals = []
    for (const name of names) {
      this.$modal.hide(name)
    }
  }

  show() {
    app.$modal.show(this.name)
  }

  hide() {
    app.$modal.hide(this.name)
  }

  attachLinkTag(msg: string, notEncode?: boolean): string {
    if (!msg) {
      return ''
    }
    let html = notEncode ? (msg || '') : BrowserService.encodeHtml(msg || '')
    try {
      html = html.replace(/(([\w-\\.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3})|((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=%]+))/gm, function (url) {
        let href = url
        if (/^[\w-\\.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(url)) {
          href = `mailto:${url}`
        } else if (url.indexOf('//') < 0) {
          href = 'https://' + url
        }
        return `<a style="word-break: break-all;" href="${href}" target="_blank">${url}</a>`
      })
    } catch (e) {
      html = html.replace(/((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=%]+)/gm, '<a style="word-break: break-all;" href="$1" target="_blank">$1</a>')
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this as any).$sanitize(html, {
      allowedTags: ['a'],
      allowedAttributes: {
        a: ['href', 'target', 'style']
      }
    })
  }

  sanitizeHtml(html: string): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this as any).$sanitize(html, {
      allowedTags: ['a', 'p', 'span', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'b', 'i', 'u', 'em', 'strong', 'strike', 'sub', 'sup', 'hr', 'br', 'blockquote', 'caption', 'code', 'pre', 'col', 'colgroup', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr', 'img', 'svg'],
      allowedAttributes: {
        '*': ['id', 'class', 'style', 'src', 'href', 'target', 'rel', 'data-block']
      }
    })
  }
}
